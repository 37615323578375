.filter-bar-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  fill: #e62049;
  color: #e62049;
  background-color: #ffffff;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #eaeaea;
  border-radius: 14px 14px 14px 14px;
  box-shadow: 10px 10px 11px 0px rgb(0 0 0 / 16%);
  height: 50px;
  line-height: 50px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  padding: 20px;
  margin-bottom: 30px;
  max-height: 7vh;
}

.tab-chart-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.chart-container {
  display: flex;
  width: 90%;
  /* justify-content: center; */
  flex-grow: 12;
}

.select-box {
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.14);
  border-radius: 9px;
  padding: 8px 16px;
  min-width: 200px;
}
.card-container {
  border: 1px solid #ccc !important;
}
.margin-left-20 {
  margin-left: 20px;
}

.loading-container {
  position: fixed;
  top: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999;
}

.loading-container__image {
  width: 30px;
}

.loading-container__text {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  fill: #e62049;
  color: #e62049;
  height: 50px;
  line-height: 50px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  padding: 20px;
  margin-bottom: 30px;
  max-height: 7vh;
}

.leftMenuIcons--chart-icon{
  width: 1.2vw;
}
