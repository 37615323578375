.lead-container {
  max-width: 66% !important;
}

.reservationDiv {
  border-bottom: 2px solid #dee2e6;
}

.reservationDiv.reservationLeftDiv {
  padding: 0.5rem;
  background: grey;
  color: #ffffff;
  width: 19%;
}

.reservationDiv.reservationRightDiv {
  padding: 0.5rem;
  background: #eaecef;
  color: #000000;
}

.marginLeft-0 {
  margin-left: 0;
  margin-right: -4rem;
}

.hand-pointer {
  cursor: pointer;
}

.activeLaedCart {
  color: #ffffff;
  background-color: #34b2ea;
}

.activeLaedCart .contactInitialIcon {
  border: 1px solid #ffffff !important;
}

.inactiveLaedCart {
  border: 1px solid #34b2ea !important;
}

.rx-border-color {
  border: 1px solid #34b2ea !important;
  border-radius: 10px;
}

.makeCallBtn {
  max-width: 3rem;
}

.divHeight100 {
  min-height: 80vh;
  max-height: 80vh;
  overflow: auto;
}

.w-5 {
  width: 4%;
}

.activeTab {
  border: 2px solid #ffffff;
  background-color: #f0385b;
  border-radius: 10px;
}

.black-background {
  background-color: #ffffff;
  padding: 2px;
  border: 1px solid #f0385b;
  border-radius: 10px;
}

.removecolorbut.maincallcalllist.btn.btn-primary {
  z-index: 9;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loginContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  flex-direction: column;
}

.logoContainer {
  display: flex;
  justify-content: center;
}

.loginItemContainer {
  width: 25vw;
  height: 50vh;
  box-shadow: -1px 4px 18px 9px #f6f6f6;
}

.headerLogo {
  width: 10vw;
}

.headerContainer {
  box-shadow: 1px 5px 0px 0px #f6f6f6;
  padding: 1vw 2vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.App {
  padding: 0;
}

.leftMenuIcons {
  width: 2vw;
}

.no-border {
  border: none;
}

.list-group-item {
  border: 1px solid #34b2ea !important;
  margin: 0.5vw 0;
  border-radius: 10px;
}

.contactInitialIcon {
  width: 45px;
  height: 45px;
  min-width: 45px;
  min-height: 45px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
}

.rx-card-header {
  background-color: #34b2ea;
  color: #ffffff;
}

.rx-card-header .contactInitialIcon {
  color: #ffffff;
  border: 1px solid #ffffff !important;
}

.rx-no-border-radius {
  border-radius: 0 !important;
}

.rx-padding-2-rem {
  padding: 0 2vw;
}

.card-body .accordion .accordion-item {
  border: 1px solid #34b2ea;
  margin: 10px;
  border-radius: 10px;
}

.card-body .accordion .accordion-item .accordion-header button {
  padding: 0;
  border-radius: 10px;
  padding-right: 1vw;
}

.card-header img {
  border: 1px solid #ffffff;
}

.card {
  border: none !important;
}

.rx-communication-header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
}

.list-group-item:first-child {
  margin-top: 0;
  border-radius: 10px;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-radius: 10px;
}

.list-group-item .name {
  font-weight: bold;
  margin-bottom: 10px;
}

.list-group-item .address {
  font-size: 12px;
  margin-bottom: 10px;
}

.list-group-item .hours {
  font-size: 12px;
  font-weight: 500;
}

.lead-details .name {
  font-weight: bold;
  margin-bottom: 10px;
}

.lead-details .address {
  font-size: 14px;
}

.searchboxsec {
  width: 100%;
  float: left;
  position: relative;
}
.searchboxsec img {
  width: 24px;
  position: absolute;
  right: 20px;
  top: 15px;
}
.searchboxsec input {
  width: 100%;
  float: left;
  padding: 16px 40px 16px 0px;
  border-radius: 32px;
  background: #f3f7fb;
  color: #313131;
  border: none;
  text-indent: 20px;
  font-size: 14px;
  outline: none;
  margin: 0 0 20px 0;
}
.searchboxsec::placeholder {
  color: #c1c7d4;
}

/* width */
::-webkit-scrollbar {
  width: 0px;
}

.rx-lead-details {
  overflow: scroll;
}

.rx-communication-history-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rx-communication-history-loader img {
  max-width: 2rem;
}

.accordion-button {
  box-shadow: none !important;
}

.rx-accordian-add-comment > .accordion-button {
  background-color: #32b3ea;
  color: #ffffff;
}
/* Track */
/* ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: #34b2ea;
  border-radius: 10px;
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #34b2ea;
} */

.noBodyContent .accordion-button::after {
  background-image: initial;
  width: 0;
}

.noBodyContent .accordion-button:not(.collapsed)::after {
  background-image: initial;
  width: 0;
}

.descMakeCallBtn,
.descNoteBtn {
  max-width: 2rem;
  padding: 0.5rem;
}
.logout-button {
  margin-left: 10px;
  cursor: pointer;
}

.notification-container {
  margin-right: 20px;
  position: relative;
  cursor: pointer;
}

.notification-badge {
  position: absolute;
  top: -5px;
  left: 15px;
  background: red;
  color: white;
  font-size: 12px;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-relative {
  position: relative;
}

.lead-notification-badge {
  top: -4px;
  left: 98%;
}

.call-notification-badge {
  left: -15px;
  top: 12px;
  height: 10px;
  width: 10px;
}

.notification-dropdown {
  position: absolute;
  top: 7%;
  right: 8%;
  width: 300px;
  height: 500px;
  overflow-x: scroll;
  background-color: #fff;
  box-shadow: 0 10px 49px rgb(0 0 0 / 10%);
  z-index: 99;
}
.notification-dropdown .notification-msg {
  font-size: 0.9rem;
  font-weight: 400;
  cursor: pointer;
  border-bottom: 1px solid #eee;
}
.notification-dropdown .notification-msg p {
  padding: 20px 20px 0px 20px;
}
.notification-date {
  font-size: 0.8rem;
  font-weight: 400;
  color: #606066;
  padding-left: 20px;
  padding-top: -5px;
}
.empty-notification {
  display: flex;
  align-items: center;
  justify-content: center;
  height: -webkit-fill-available;
}
.button-container {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
  margin-top: 10px;
}
